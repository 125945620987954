<template>
  <v-app>
    <transition name="fade-in-up">
      <div>
        <div class="card card-custom card-stretch">
          <div class="card-body pt-4">
            <h2>Hướng dẫn tích hợp</h2>
            <br /><br />
            <h3>1. Tạo 1 đơn hàng</h3>
            <table>
              <tbody>
                <tr>
                  <td style="min-width: 150px">URL:</td>
                  <td>
                    <code
                      >http://103.15.50.244:9089/autmprivate/accoutant/makeorder</code
                    >
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Method:</td>
                  <td>POST</td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Header:</td>
                  <td>Authorization: Private <code>SITE_PRIVATE_KEY</code></td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Form data:</td>
                  <td>
                    <table class="params">
                      <tbody>
                        <tr>
                          <th>Tham số</th>
                          <th>Kiểu dữ liệu</th>
                          <th>Mô tả</th>
                        </tr>
                        <tr>
                          <td>Amount <span class="text-danger">*</span></td>
                          <td>Integer</td>
                          <td>Tổng số tiền đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Order_code</td>
                          <td>String</td>
                          <td>Mã đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Product_code</td>
                          <td>String</td>
                          <td>Mã sản phẩm</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>Integer</td>
                          <td>Số lượng sản phẩm</td>
                        </tr>
                        <tr>
                          <td>Reg_adr</td>
                          <td>String</td>
                          <td>Địa chỉ nhận hàng</td>
                        </tr>
                        <tr>
                          <td>Reg_phone <span class="text-danger">*</span></td>
                          <td>String</td>
                          <td>Số điện thoại người nhận</td>
                        </tr>
                        <tr>
                          <td>User_name</td>
                          <td>String</td>
                          <td>Tên đăng nhập người nhận</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="params-note text-right text-muted">
                      <span class="text-danger">*</span> là tham số bắt buộc
                      phải truyền lên
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style="min-width: 150px">Response data:</td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <h4>Ví dụ</h4>
            <pre>
curl -X POST \
http://103.15.50.244:9089/autmprivate/accoutant/makeorder \
-H 'authorization: Private eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRtIjoiYXV0bSIsImV4cCI6MTY5OTg4NjM0NCwiaW5mb3JtYXRpb24iOiJhdXRvIGdlbmFyYXRlIC0gYXV0bSBzeXN0ZW0iLCJuYW1lIjoic2l0ZTEifQ.-eRAj_2HtW6p7g9j0qy8iXRHOALwyzy-qLIajfbdz7Q' \
-H 'cache-control: no-cache' \
-H 'content-type: application/json' \
-d '{
"Amount": 98000,
"Order_code": "None",
"Product_code": "None",
"Quantity": 1,
"Reg_adr": "hanoidunrom",
"Reg_phone": "0975648243",
"User_name": "Lecuong"
}'
</pre
            >

            <h5>Kết quả trả về:</h5>
            <pre>
{
  "Message": "Success",
  "Data": {
    "Id": 78,
    "Reg_phone": "0975648243",
    "User_name": "Lecuong",
    "Reg_adr": "hanoidunrom",
    "Order_time": 1638524023,
    "Quantity": 1,
    "Amount": 98000,
    "Expected_time": 0,
    "Order_from": 2,
    "Order_code": "None",
    "Product_code": "None",
    "Status": 0,
    "Delete_reason": ""
  }
}
                                                </pre
            >

            <br /><br />
            <hr />
            <br />

            <h3>2. Tạo nhiều đơn hàng</h3>
            <table>
              <tbody>
                <tr>
                  <td style="min-width: 150px">URL:</td>
                  <td>
                    <code
                      >http://103.15.50.244:9089/autmprivate/accoutantV2/makeorder</code
                    >
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Method:</td>
                  <td>POST</td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Header:</td>
                  <td>Authorization: Private <code>SITE_PRIVATE_KEY</code></td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Form data:</td>
                  <td>
                    <table class="params">
                      <tbody>
                        <tr>
                          <th>Tham số</th>
                          <th>Kiểu dữ liệu</th>
                          <th>Mô tả</th>
                        </tr>
                        <tr>
                          <td>Collection_product</td>
                          <td>Array</td>
                          <td>Mảng object các sản phẩm</td>
                        </tr>
                        <tr>
                          <td>Order_code</td>
                          <td>String</td>
                          <td>Mã đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Reg_adr</td>
                          <td>String</td>
                          <td>Địa chỉ nhận hàng</td>
                        </tr>
                        <tr>
                          <td>Reg_phone</td>
                          <td>String</td>
                          <td>SĐT nhận hàng</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>Integer</td>
                          <td>
                            Trạng thái đơn hàng <br />
                              0: Chờ xác nhận <br />
                              3: Đã lên đơn <br />
                              1: Đã chốt đơn <br />
                              5: Đã huỷ
                          </td>
                        </tr>
                        <tr>
                          <td>User_name</td>
                          <td>String</td>
                          <td>Tên đăng nhập người nhận</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>Ví dụ</h4>
            <pre>
curl -X POST \
http://103.15.50.244:9089/autmprivate/accoutantV2/makeorder \
-H 'authorization: Private eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRtIjoiYXV0bSIsImV4cCI6MTY5OTg4NjM0NCwiaW5mb3JtYXRpb24iOiJhdXRvIGdlbmFyYXRlIC0gYXV0bSBzeXN0ZW0iLCJuYW1lIjoic2l0ZTEifQ.-eRAj_2HtW6p7g9j0qy8iXRHOALwyzy-qLIajfbdz7Q' \
-H 'cache-control: no-cache' \
-H 'content-type: application/json' \
-d '{
  "Collection_product": [
    {
      "Amount": 1111,
      "Product_code": "Product_code 1",
      "Product_name": "Product_name 1",
      "Quantity": 1
    },
    {
      "Amount": 2222,
      "Product_code": "Product_code 2",
      "Product_name": "Product_name 2",
      "Quantity": 1
    }
  ],
  "Order_code": "Order_code 2",
  "Reg_adr": "hanoi",
  "Reg_phone": "09754545454",
  "Status": 3,
  "User_name": "username1"
}'
</pre
            >

            <h5>Kết quả trả về:</h5>
            <pre>
{
    "Message": "Success",
    "Data": {
        "Id": 0,
        "Reg_phone": "09754545454",
        "User_name": "username1",
        "Reg_adr": "hanoi",
        "Order_time": 0,
        "Quantity": 0,
        "Amount": 0,
        "Expected_time": 0,
        "Order_from": 3,
        "Order_code": "Order_code 2",
        "Product_code": "",
        "Status": 3,
        "Delete_reason": ""
    }
}
                                                </pre
            >

            <br /><br />
            <hr />
            <br />

            <h3>3. Sửa 1 đơn hàng</h3>
            <table>
              <tbody>
                <tr>
                  <td style="min-width: 150px">URL:</td>
                  <td>
                    <code
                      >http://103.15.50.244:9089/autmprivate/accoutant/updateorder</code
                    >
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Method:</td>
                  <td>POST</td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Header:</td>
                  <td>Authorization: Private <code>SITE_PRIVATE_KEY</code></td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Form data:</td>
                  <td>
                    <table class="params">
                      <tbody>
                        <tr>
                          <th>Tham số</th>
                          <th>Kiểu dữ liệu</th>
                          <th>Mô tả</th>
                        </tr>
                        <tr>
                          <td>Id <span class="text-danger">*</span></td>
                          <td>Integer</td>
                          <td>Id đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>Integer</td>
                          <td>
                            <span>
                              Trạng thái đơn hàng <br />
                              0: Chờ xác nhận <br />
                              3: Đã lên đơn <br />
                              1: Đã chốt đơn <br />
                              5: Đã huỷ
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Delete_reason</td>
                          <td>String</td>
                          <td>
                            <span>
                              Lý do huỷ đơn hàng. Nếu cập nhật Status = 5 (Huỷ
                              đơn hàng) thì cần thêm trường Delete_reason
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Amount</td>
                          <td>Integer</td>
                          <td>Tổng số tiền đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Order_code</td>
                          <td>String</td>
                          <td>Mã đơn hàng</td>
                        </tr>
                        <tr>
                          <td>Product_code</td>
                          <td>String</td>
                          <td>Mã sản phẩm</td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>Integer</td>
                          <td>Số lượng sản phẩm</td>
                        </tr>
                        <tr>
                          <td>Reg_adr</td>
                          <td>String</td>
                          <td>Địa chỉ nhận hàng</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="params-note text-right text-muted">
                      <span class="text-danger">*</span> là tham số bắt buộc
                      phải truyền lên
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>Ví dụ</h4>
            <pre>
curl -X POST \
http://103.15.50.244:9089/autmprivate/accoutant/updateorder \
-H 'authorization: Private eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRtIjoiYXV0bSIsImV4cCI6MTY5OTg4NjM0NCwiaW5mb3JtYXRpb24iOiJhdXRvIGdlbmFyYXRlIC0gYXV0bSBzeXN0ZW0iLCJuYW1lIjoic2l0ZTEifQ.-eRAj_2HtW6p7g9j0qy8iXRHOALwyzy-qLIajfbdz7Q' \
-H 'cache-control: no-cache' \
-H 'content-type: application/json' \
-d '{
"Id": 80,
"Amount": 100000,
"Status": 1
}'
</pre
            >

            <h5>Kết quả trả về:</h5>
            <pre>
{
  "Message": "Success",
  "Data": null
}
                                                </pre
            >

            <br /><br />
            <hr />
            <br />

            <h3>4. Sửa nhiều đơn hàng</h3>
            <table>
              <tbody>
                <tr>
                  <td style="min-width: 150px">URL:</td>
                  <td>
                    <code
                      >http://103.15.50.244:9089/autmprivate/accoutantV2/updateorder</code
                    >
                  </td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Method:</td>
                  <td>PUT</td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Header:</td>
                  <td>Authorization: Private <code>SITE_PRIVATE_KEY</code></td>
                </tr>
                <tr>
                  <td style="min-width: 150px">Form data:</td>
                  <td>
                    Giống data phần tạo nhiều đơn hàng
                  </td>
                </tr>
              </tbody>
            </table>

            <h4>Ví dụ</h4>
            <pre>
curl -X POST \
http://103.15.50.244:9089/autmprivate/accoutantV2/updateorder \
-H 'authorization: Private eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRtIjoiYXV0bSIsImV4cCI6MTY5OTg4NjM0NCwiaW5mb3JtYXRpb24iOiJhdXRvIGdlbmFyYXRlIC0gYXV0bSBzeXN0ZW0iLCJuYW1lIjoic2l0ZTEifQ.-eRAj_2HtW6p7g9j0qy8iXRHOALwyzy-qLIajfbdz7Q' \
-H 'cache-control: no-cache' \
-H 'content-type: application/json' \
-d '{
  "Collection_product": [
    {
      "Amount": 1222,
      "Product_code": "Product_code 1",
      "Product_name": "Product_name 1",
      "Quantity": 1
    },
    {
      "Amount": 2111,
      "Product_code": "Product_code 2",
      "Product_name": "Product_name 2",
      "Quantity": 1
    }
  ],
  "Order_code": "Order_code 2",
  "Reg_adr": "hanoi cho dun rommmmm",
  "Reg_phone": "0976548243",
  "Status": 3,
  "User_name": "username1"
}


'
</pre
            >

            <h5>Kết quả trả về:</h5>
            <pre>
{
    "Message": "Success",
    "Data": {
        "Id": 0,
        "Reg_phone": "0976548243",
        "User_name": "username1",
        "Reg_adr": "hanoi cho dun rommmmm",
        "Order_time": 0,
        "Quantity": 0,
        "Amount": 0,
        "Expected_time": 0,
        "Order_from": 3,
        "Order_code": "Order_code 2",
        "Product_code": "",
        "Status": 3,
        "Delete_reason": ""
    }
}
                                                </pre
            >
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>


<style lang="scss" scoped>
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

tr {
  padding: 10px;
  height: 50px;
}
.theme--light.v-application code {
  padding: 2px 4px;
  font-size: 100%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
table.params {
  border-collapse: collapse;
  width: 100%;
}
table.params tr:nth-child(even) {
  background-color: #f6f7f8;
}
table.params td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
table {
  font-family: arial, sans-serif;
  font-size: 1.2em;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  metaInfo: {
    title: "Hướng dẫn tích hợp",
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  components: {},
  methods: {},
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [ { title: "Category", route: "index" }, { title: "Thêm category" } ]);
  },
};
</script>



